import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  SidebarSubmenuItem, SidebarSubmenu
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';
import GroupIcon from '@material-ui/icons/People';
import MuiMemoryIcon from '@material-ui/icons/Memory';
import MuiExtensionIcon from '@material-ui/icons/Extension';
import Apps from '@material-ui/icons/Apps';
import { identityApiRef, useApi, useApp } from '@backstage/core-plugin-api';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BuildIcon from '@material-ui/icons/Build';
import CodeIcon from '@material-ui/icons/Code';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';



const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="catalog" text="Home" />
        <SidebarDivider />
        <SidebarItem icon={Apps} to="catalog?filters[kind]=system" text="System" /> 
        <SidebarItem icon={MuiMemoryIcon} to="catalog?filters[kind]=component" text="Services/Component"  />
        <SidebarItem icon={MuiExtensionIcon} to="catalog?filters[kind]=api" text="API" />
        <SidebarDivider color='black' />
        <MyGroupsSidebarItem
          singularTitle="My Group"
          pluralTitle="My Groups"
          icon={GroupIcon}
        />
        {/*<SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs Doc" />*/}
        <SidebarItem icon={useApp().getSystemIcon('kind:resource') || ExtensionIcon} to="newcomponent" text="Create" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Register Component..." />
        <SidebarItem icon={BuildIcon} to="entity-validation" text="Validator" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={DeveloperModeIcon} to="Optimus" text="DevTools">
        <SidebarSubmenu title="Optimus">
            <SidebarSubmenuItem
              icon={CodeIcon}
              title="Optimus Initializer Front"
              to="/optimus-initializer"
            />
            <SidebarSubmenuItem
              icon={DeveloperModeIcon}
              title="Command Console"
              to="/optimus-console"
            />
          </SidebarSubmenu>
          </SidebarItem>

          {/* End global nav */}
          <SidebarDivider />
          <SidebarScrollWrapper>
            {/* Items in this group will be scrollable if they run out of space */}
          </SidebarScrollWrapper>
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
      <Logout />
    </Sidebar>
    {children}
  </SidebarPage>
);
const Logout = () => {
  const identityApi = useApi(identityApiRef);
  return (<SidebarItem icon={ExitToAppIcon} text="Logout" onClick={() => identityApi.signOut()}> </SidebarItem>)

}