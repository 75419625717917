import React, { useEffect, useState } from 'react';
import { Page, Header, Content } from '@backstage/core-components';
import { useApi, identityApiRef } from '@backstage/core-plugin-api';

const OptimusConsole = () => {
  const identityApi = useApi(identityApiRef);
  const [userId, setUserId] = useState('');
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    const fetchProfileInfo = async () => {
      const profileInfo = await identityApi.getProfileInfo();
      const userId = profileInfo.email ? profileInfo.email.split('@')[0] : ''; // Extrae userId del email
      setUserId(userId);

      // Simulate an authorization check
      const checkAuthorization = async () => {
        // Replace this with actual authorization logic
        const hasPermission = true; // Example: await someAuthCheckFunction(userId);
        setAuthorized(hasPermission);
      };

      checkAuthorization();
    };

    fetchProfileInfo();
  }, [identityApi]);

  useEffect(() => {
    if (authorized && userId) {
      const externalUrl = `https://pkgs.dev.azure.com/BancoPichinchaEC/arq-framework/_apis/packaging/feeds/09cbe15a-db86-43b5-a2a7-3b2a08cd2417/maven/com.pichincha/optimus-framework/2.9.0/optimus-framework-2.9.0.jar/content`;
      const link = document.createElement('a');
      link.href = externalUrl;
      link.download = 'optimus-framework-2.9.0.jar';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Ejecuta el comando java -jar en una nueva ventana de consola
      const command = `java -jar ${link.download}`;
      const platform = navigator.platform;

      if (platform.startsWith('Win')) {
        window.open(`cmd.exe /K "${command}"`, '_blank');
      } else if (platform.startsWith('Mac')) {
        window.open(`osascript -e 'tell application "Terminal" to do script "${command}"'`, '_blank');
      } else {
        window.open(`gnome-terminal -- ${command}`, '_blank');
      }
    }
  }, [authorized, userId]);

  if (!authorized) {
    return (
      <Page themeId="tool">
        <Header title="Optimus Console" />
        <Content>
          <div style={{ padding: '20px', textAlign: 'center' }}>
            <h2>You do not have permission to view this page.</h2>
          </div>
        </Content>
      </Page>
    );
  }

  return (
    <Page themeId="tool">
      <Header title="Optimus Console" />
      <Content>
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <h2>You are being redirected to download and execute Optimus Console...</h2>
        </div>
      </Content>
    </Page>
  );
};

export default OptimusConsole;
